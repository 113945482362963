.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  margin: 0 -20px;
  border-top: 3px solid var(--primary-background-color);
}
.footer div button:last-child {
  margin-left: 10px;
}
