.scrollArea {
  overflow: hidden;
  position: relative;
}
.scrollArea .scrollable {
  max-height: inherit;
  overflow: hidden;
  zoom: 1;
}
.scrollArea .scrollable.scrollX {
  overflow-x: scroll;
}
.scrollArea .scrollable.scrollY {
  overflow-y: scroll;
}
.scrollArea.fit {
  height: 100%;
  width: 100%;
}
.scrollArea.fit .scrollable {
  bottom: 0;
  left: 0;
  max-height: none;
  position: absolute;
  right: 0;
  top: 0;
}
.scrollArea .scrollNav {
  background-color: var(--active-color);
  cursor: pointer;
  height: 24px;
  left: 10px;
  position: absolute;
  right: 10px;
  transition: all 0.3s ease;
  padding-left: 5px;
}
.scrollArea .scrollNav .scrollNavIcon {
  color: var(--active-color);
  display: block;
  width: 18px;
  height: 18px;
  margin: 0 auto;
  transition: color 0.3s ease;
}
.scrollArea .scrollNav:hover {
  box-shadow: 0 0 10px 10px rgba(0,0,0,0.9);
}
.scrollArea .scrollNav:hover .scrollNavIcon {
  color: var(--hover-color);
}
.scrollArea .scrollNavUp {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  top: 0;
  transform: translateY(-24px);
  padding-top: 3px;
}
.scrollArea .scrollNavDown {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  bottom: 0;
  transform: translateY(24px);
  padding-top: 5px;
}
.scrollArea.canScrollUp .scrollNavUp,
.scrollArea.canScrollDown .scrollNavDown {
  opacity: 1;
  transform: translateY(0);
  box-shadow: 0 0 10px 10px rgba(0,0,0,0.75);
}
